<template>
    <Bell/>
</template>

<script>
import Bell from '@apps/Notifications/views/Bell'
export default {
    components: {
        Bell
    }
}
</script>